<template>
  <div>

    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
      <h4>Trainee Roles Index</h4>
      <!-- Create Button Organization -->
      <router-link :to="{ name: 'TraineeRoleNew' }" tag="button" type="button" class="btn btn-success">Create Trainee Role</router-link>
    </div>
    <div v-if="traineeRoles">

      <!-- Organization Index -->
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="rainbow-background"></div>
            <!-- Table Headers and Search Bar -->
            <ransack-filtering :headerWidth='100' :searchParam="searchParam" :tableHeaders="headers" @onChange="setFilters"></ransack-filtering>

            <div class="table-responsive">
              <table class="card-table table">
                <tbody>
                <tr v-for="traineeRole in traineeRoles" :key="traineeRole.id" :id="traineeRole.id">
                  <td class="text-left">
                    {{ traineeRole.title }}
                  </td>
                  <td class="w-50">
                    <div class="btn-group float-right">
                      <router-link :to="{ name: 'TraineeRoleEdit', params: { id: traineeRole.id } }" tag="button" class="btn btn-secondary" type="button">Edit</router-link>
                      <router-link :to="{ name: 'TraineeRoleShow', params: { id: traineeRole.id } }" tag="button" class="btn btn-primary" type="button">Show</router-link>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>TOTAL</th>
                  <td>{{ traineeRoles.length }}</td>
                </tr>
                </tbody>
              </table>
            </div>

            <!-- Pagination -->
            <pagination :totalRows="totalRows" :page="currentPage" :numOfRecords="perPage" @onChange="onChange"></pagination>

          </div>
        </div>
      </div>
    </div>

    <loading-overlay v-else></loading-overlay>
  </div>
</template>

<script>

import { TraineeRoleService } from '@/common/services/trainee_role.service';
import { FilterService } from '@/common/services/filter.service';
import Pagination from '@/common/components/pagination.vue';
import RansackFiltering from '@/common/components/ransack-filtering.vue';
import LoadingOverlay from '@/common/components/loading-overlay.vue';

export default {
  name: 'ActivityIndex',
  components: {
    Pagination,
    RansackFiltering,
    LoadingOverlay,
  },
  data() {
    return {
      traineeRoles: null,
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      totalRecords: 0,
      headers: ['Title'],
      searchParam: 'title_cont',
      filters: FilterService.defaultData(),
    };
  },
  created() {
    this.getTraineeRoles(this.currentPage, this.perPage);
  },
  methods: {
    getTraineeRoles(page, perPage) {
      const params = FilterService.build({ page, per_page: perPage }, this.filters);
      TraineeRoleService.query(params).then((response) => {
        this.currentPage = parseFloat(response.headers['x-page']);
        this.totalRows = parseFloat(response.headers['x-total']);
        this.traineeRoles = response.data;
      });
    },
    onChange(page, perPage) {
      this.getTraineeRoles(page, perPage);
    },
    setFilters(filters) {
      this.filters = filters;
      this.getTraineeRoles(this.currentPage, this.perPage);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../styles/views/trainee_roles";
</style>
